import { ref, ssrRef, useContext } from '@nuxtjs/composition-api';
import { Meta, Params, ServiceCenter } from '@webplatform/cms-js-sdk';

export default (params: Params) => {
  const {
    $api: { serviceCentersService },
  } = useContext();
  const centers = ref<ServiceCenter[]>([]);
  const pending = ref(false);
  const error = ssrRef(false, 'error');
  const meta = ref<Meta>();

  const fetchServiceCenters = async () => {
    error.value = false;
    pending.value = true;

    try {
      const { data, meta: serviceCentersMeta } =
        await serviceCentersService.getAllServiceCenters(params);
      centers.value = data;
      meta.value = serviceCentersMeta;
    } catch (e) {
      error.value = true;
    } finally {
      pending.value = false;
    }
  };

  return {
    centers,
    meta,
    pending,
    error,
    fetchServiceCenters,
  };
};
