import { CmsApiClient } from '@webplatform/cms-js-sdk';

export * from '@webplatform/cms-js-sdk';
export * from './types';
export * from './composables';
declare module '@nuxt/types' {
  interface Context {
    $api: CmsApiClient;
  }
}
