import { ref, ssrRef, useContext } from '@nuxtjs/composition-api';
import { CountriesParams, Country, Meta } from '@webplatform/cms-js-sdk';

export default (params: CountriesParams) => {
  const {
    $api: { countriesService },
  } = useContext();
  const countries = ref<Country[]>([]);
  const pending = ref(false);
  const error = ssrRef(false, 'error');
  const meta = ref<Meta>();

  const fetchCountries = async () => {
    error.value = false;
    pending.value = true;

    try {
      const { data, meta: countriesMeta } =
        await countriesService.getCountriesByIds({
          limit: 1000,
          ...params,
        });

      countries.value = data;
      meta.value = countriesMeta;
    } catch (e) {
      error.value = true;
    } finally {
      pending.value = false;
    }
  };

  return {
    countries,
    meta,
    pending,
    error,
    fetchCountries,
  };
};
